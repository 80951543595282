import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Team Logo",
  code: "TeamLogo"
};
export const _frontmatter = {
  "menuLabel": "Team Logo",
  "sortOrder": 20.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Team Logo`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-teamlogo--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { TeamLogo } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`TeamLogo`}</inlineCode>{` component can be passed a few things to the name prop to resolve a major sports team logo: `}</p>
    <ul>
      <li parentName="ul">{`The Full Team Name (e.g. New York Knicks)`}</li>
      <li parentName="ul">{`The Short Name for the team (e.g. NY Knicks)`}</li>
      <li parentName="ul">{`The league + abbreviation for the team (e.g. nba-nyk)`}</li>
    </ul>
    <p>{`For College logos, the same applies with these additional requirements:`}</p>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`sport`}</inlineCode>{` prop needs to be passed that tells the component which sport (or competition) we're dealing with (e.g. 'basketball', or 'ncaab')`}</li>
      <li parentName="ul">{`You can pass an alternate name for a school as well - for when univerisities have several name variations (for example, the Tennessee Volunteers and the Tennessee Lady Volunteers)`}</li>
      <li parentName="ul">{`To use the abbreviation, you must prefix it with `}<inlineCode parentName="li">{`college`}</inlineCode>{`, (e.g. `}<inlineCode parentName="li">{`college-duke`}</inlineCode>{`)`}</li>
    </ul>
    <p>{`The full list of TeamLogos can be viewed here `}<a parentName="p" {...{
        "href": "/foundations/iconography"
      }}>{`Iconography`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20 " mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Team Logo component provides a visually distinguishable representation of major sports team logos, as well as college icons with team colors applied. It utilizes appropriate sizing based on semantic themes, allowing users to perceive and differentiate between different team logos. The component provides both light mode and dark mode versions when available, ensuring visibility based on the user's chosen color scheme.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    The Team Logo component itself is not directly operable, as it is a visual representation of a team logo. However, it can be interacted with indirectly through associated actions or content. For example, clicking on the Team Logo component may trigger an action related to the team or provide additional information.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Team Logo component is clear, providing a visual representation of major sports team logos and college icons with team colors. Users can easily identify and associate the team logos with their respective sports teams or colleges. The component allows different name formats to resolve the appropriate logo.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Team Logo component is designed to be compatible with assistive technologies, ensuring that screen readers can convey the team logo information to visually impaired users. It functions consistently across different browsers and platforms. The component supports different sizes based on semantic themes, providing flexibility for various use cases. The light mode and dark mode versions accommodate different color schemes.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      